<template>
  <ModalWrapper>
    <div class="modal">
      <div class="modal__title">{{ title }}</div>
      <div v-if="plateTruck" class="modal__plateTruck">
        <div class="modal__plateTruck-icon">
          <img src="@/assets/icons/Truck.svg" alt="icon" />
        </div>
        <div class="modal__plateTruck-number">
          {{ plateTruck }}
        </div>
      </div>
      <div v-if="description" class="modal__description">{{ description }}</div>
      <div class="modal__buttons">
        <MainButton
          :title="cancel"
          type="secondary"
          size="small"
          width="half"
          @button-clicked="$emit('cancel')"
        />
        <MainButton
          :title="accept"
          type="primary"
          size="small"
          width="half"
          @button-clicked="$emit('accept')"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import ModalWrapper from '@/views/terminalUI/components/modals/ModalWrapper'
export default {
  name: 'CommonQueueModal',
  components: { ModalWrapper, MainButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    plateTruck: {
      type: String,
      default: '',
    },
    cancel: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  width: 700px
  padding: 40px 40px 24px
  box-shadow: 0 0 20px rgba(132, 132, 132, 0.2)
  border-radius: 30px
  background: $color-white
  color: $secondaryBlack
  &__title
    margin-bottom: 16px
    font-family: $fontPrimary
    font-size: 32px
    font-weight: 500
    line-height: 40px
  &__description
    margin-bottom: 36px
    font-family: $fontPrimary
    font-size: 20px
    font-weight: 500
    line-height: 28px
  &__plateTruck
    display: flex
    align-items: center
    margin-top: 26px
    margin-bottom: 26px
    &-icon
      display: flex
      justify-content: center
      align-items: center
      width: 36px
      height: 36px
      margin-right: 14px
      background: $backgroundGray
      border-radius: 8px
    &-number
      font-family: $fontPrimary
      font-size: 40px
      font-weight: 500
      line-height: 48px
      color: $secondaryBlack
  &__buttons
    display: flex
    gap: 24px
</style>
