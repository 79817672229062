<template>
  <section class="modal-wrapper">
    <slot />
  </section>
</template>

<script>
export default {
  name: "ModalWrapper",
}
</script>

<style lang="sass" scoped>
.modal-wrapper
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background: rgba(43, 43, 43, 0.4)
  z-index: 100
</style>
