var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalWrapper", [
    _c("div", { staticClass: "modal" }, [
      _c("div", { staticClass: "modal__title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.plateTruck
        ? _c("div", { staticClass: "modal__plateTruck" }, [
            _c("div", { staticClass: "modal__plateTruck-icon" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/icons/Truck.svg"),
                  alt: "icon",
                },
              }),
            ]),
            _c("div", { staticClass: "modal__plateTruck-number" }, [
              _vm._v(" " + _vm._s(_vm.plateTruck) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.description
        ? _c("div", { staticClass: "modal__description" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "modal__buttons" },
        [
          _c("MainButton", {
            attrs: {
              title: _vm.cancel,
              type: "secondary",
              size: "small",
              width: "half",
            },
            on: {
              "button-clicked": function ($event) {
                return _vm.$emit("cancel")
              },
            },
          }),
          _c("MainButton", {
            attrs: {
              title: _vm.accept,
              type: "primary",
              size: "small",
              width: "half",
            },
            on: {
              "button-clicked": function ($event) {
                return _vm.$emit("accept")
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }